import React, { useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import firebase from "firebase/app"
import "firebase/auth"
import { Link, useHistory } from "react-router-dom"
import styles from "./Register.module.css"
import logo from "../digicust.png"
import { Paper, Step, StepLabel, Stepper } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function Register() {
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [acceptPrivacy, setAcceptPrivacy] = useState(false)

  const history = useHistory()

  return (
    <Container maxWidth='md'>
      <Grid container spacing={3}>
        <Grid item md={7}>
          <a href='https://www.digicust.com'>
            <img className={styles.logo} src={logo} alt='Digicust Logo' />
          </a>
          <h1>Convert Product Descriptions To HS-Codes</h1>
          <p>
            <b>Your free trial account is just a few clicks ahead.</b> Since it is a free service, we won’t ask you for any credit card information. You will get a unique opportunity to test our AI-driven HS-classification <b>of globally valid 6-digits codes</b> with up to 20 classifications per day. <b>Once an HS-code is assigned, you can conveniently select CN or TARIC-codes of your choice from the cut out goods nomenclature.</b>
          </p>
          <p>
            We understand that every process is specific and that an API or an
            upload function with large volume classifications would fit your
            requirements maybe better. Hence, we are looking forward to
            discussing your needs once you have enjoyed our free trial version.
          </p>
        </Grid>
        <Grid item md={5}>
          <Container component='main' maxWidth='xs'>
            <Paper className={styles.paper}>
              <CssBaseline />
              <Stepper>
                {["", ""].map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className={classes.paper}>
                <form
                  onSubmit={(event) => {
                    event.preventDefault()
                    if (password === repeatPassword) {
                      firebase
                        .auth()
                        .createUserWithEmailAndPassword(email, password)
                        .then((user) => {
                          history.push("/")
                        })
                        .catch((error) => {
                          var errorCode = error.code
                          var errorMessage = error.message
                          console.log(errorMessage)
                          alert("We could not register you. " + errorMessage)
                        })
                    } else {
                      alert("Passwords are not equal.")
                    }
                  }}
                  className={classes.form}
                  noValidate>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    value={email}
                    autoComplete='email'
                    onChange={(event) => {
                      setEmail(event.target.value)
                    }}
                    autoFocus
                  />
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    value={password}
                    autoComplete='current-password'
                    onChange={(event) => {
                      setPassword(event.target.value)
                    }}
                  />

                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='password2'
                    label='Repeat password'
                    type='password'
                    id='password2'
                    value={repeatPassword}
                    onChange={(event) => {
                      setRepeatPassword(event.target.value)
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acceptPrivacy}
                        onChange={(event) => {
                          setAcceptPrivacy(event.target.checked)
                        }}
                        color='primary'
                      />
                    }
                    label={
                      <p>
                        I accept the{" "}
                        <a
                          target='_blank'
                          href='https://www.digicust.com/english-version/company/digicust-gdpr'>
                          Privacy Policy
                        </a>
                      </p>
                    }
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={!acceptPrivacy}
                    className={classes.submit}>
                    Get Started
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link to='/login' variant='body2'>
                        Already have an account? Login
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </Container>
  )
}
