import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import firebase from "firebase/app"
import "firebase/auth"
import { Link, useHistory } from "react-router-dom"
import styles from "./Login.module.css"
import logo from "../digicust.png"
import { Fragment } from "react"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function Login() {
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const history = useHistory()

  return (
    <Fragment>
      <a href='https://www.digicust.com'>
        <img className={styles.logo} src={logo} alt='Digicust Logo' />
      </a>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((user) => {
                  history.push("/")
                })
                .catch((error) => {
                  var errorCode = error.code
                  var errorMessage = error.message
                  console.log(errorMessage)
                  alert("We could not log you in. " + errorMessage)
                })
            }}
            className={classes.form}
            noValidate>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              value={email}
              autoComplete='email'
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              autoFocus
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              value={password}
              autoComplete='current-password'
              onChange={(event) => {
                setPassword(event.target.value)
              }}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}>
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to='/register' variant='body2'>
                  No account? Register!
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </Fragment>
  )
}
