import React, { useState, useEffect, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import styles from "./Classifier.module.css"
import logo from "../digicust.png"

import { Button, Paper, TextField } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"

import firebase from "firebase/app"

//import ClassifierConfig from "../classifier.config.json";

export default function Classifier() {
  const [user, setUser] = useState(null)
  const [product, setProduct] = useState("")
  const [open, setOpen] = useState(false)
  const [hsCode, setHsCode] = useState("HS Code")
  firebase.auth().onAuthStateChanged(function (user) {
    setUser(user)
    checkLimit()
  })

  async function checkLimit() {
    if (user) {
    }
  }

  const classifyCallback = (results) => {
    handleClose()
    setHsCode(results.hsCode)
  }
  const classifyAbort = (results) => {
    console.log("user aborted classification")
    const iframe = document.getElementById("ccce-classyvue")
    iframe.src = ""
    handleClose()
  }

  const classify = () => {
    window.ccce.classify(
      {
        product: product,
        destination: "DE",
        origin: "CN",
        lang: "en-us",
        useKeyboard: true,
        hs6: false,
      },
      classifyCallback,
      classifyAbort
    )
  }

  const onProductChange = (e) => {
    setProduct(e.target.value)
  }

  useEffect(() => {
    const script = document.getElementById("classifierScript")
    script.setAttribute("data-profile-id", process.env.REACT_APP_CLASSIFY_ID)
    const el = document.querySelector("script[data-element-id]")
    window.ccce.init(el)
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = () => {
    setOpen(true)
    classify()
  }

  return (
    <Fragment>
      <a href='https://www.digicust.com'>
        <img className={styles.logo} src={logo} alt='Digicust Logo' />
      </a>
      <Paper>
        {" "}
        <div className={styles.classificationWrapper}>
          <Typography>
            Type in a product description to receive the corresponding HS code.
          </Typography>
          <div className={styles.productInput}>
            <TextField
              label='Product Description'
              variant='outlined'
              onChange={onProductChange}
            />
            <Button
              size='large'
              style={{ boxShadow: "none", marginLeft: "10px" }}
              variant='contained'
              onClick={handleClick}>
              Classify
            </Button>
          </div>

          <TextField
            className={styles.hsCode}
            value={hsCode}
            disabled
            variant='outlined'
          />
          <div
            className={styles.dialogBackground}
            style={{ display: open ? "block" : "none" }}
          />
          <div
            id='classification'
            style={{ display: open ? "block" : "none" }}
            className={styles.classification}
          />
        </div>
      </Paper>
    </Fragment>
  )
}
