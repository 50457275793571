import React, { useState } from "react"
import styles from "./App.module.css"
import { Fragment } from "react"
import logo from "./digicust.png"
import { Box, Button, Card, ListItemIcon, Typography } from "@material-ui/core"
import Classifier from "./components/Classifier"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/storage"
import "firebase/firestore"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import Login from "./components/Login"
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"
import { useHistory } from "react-router-dom"
import {
  AccountCircleRounded,
  AssignmentIndRounded,
  FreeBreakfastRounded,
  VpnKeyRounded,
} from "@material-ui/icons"
import Register from "./components/Register"

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyDnkxT6m2drfdOH9ZuhIzwtPC0pqjEI8pw",
    authDomain: "digicust-staging.firebaseapp.com",
    databaseURL: "https://digicust-staging.firebaseio.com",
    projectId: "digicust-staging",
    storageBucket: "digicust-staging.appspot.com",
    messagingSenderId: "595984959131",
    appId: "1:595984959131:web:2c4bbf26b7a4802fa3683e",
  })
}

function App() {
  const history = useHistory()
  const [user, setUser] = useState(null)
  firebase.auth().onAuthStateChanged(function (user) {
    setUser(user)
  })
  return (
    <Fragment>
      <Router>
        <body className={styles.App}>
          <Switch>
            <Route path='/login'>
              <Login />
            </Route>
            <Route path='/'>{user ? <Classifier /> : <Register />}</Route>
          </Switch>
          <Box mt={8}>
            <Typography variant='body2' color='textSecondary' align='center'>
              {"Copyright © "}
              <a color='inherit' href='https://digicust.com/'>
                Digicust
              </a>{" "}
              {new Date().getFullYear()}
              {". "}
              <a href='https://www.digicust.com/english-version/company/digicust-legal-information'>Imprint</a>{" "}
              <a href='https://www.digicust.com/english-version/company/digicust-gdpr'>
                Privacy Policy
              </a>{" "}
              {user ? (
                <a
                  onClick={() => {
                    firebase
                      .auth()
                      .signOut()
                      .then(function () {
                        history.push("/login")
                      })
                      .catch(function (error) {
                        // An error happened.
                      })
                  }}>
                  Logout
                </a>
              ) : null}
            </Typography>
          </Box>
        </body>
      </Router>
    </Fragment>
  )
}

export default App
